import { Dialog, DialogContent, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { AuthState } from '../../services/auth';
import { PopupHeader } from '../popup-header';
import { RegistrationForm } from './forms/register-form';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    padding: theme.spacing(2, 0),
    margin: theme.spacing(2, 2, 0, 2),
    borderTop: '1px solid #ccc',
  },
  footerText: {
    fontSize: '0.8em',
  },

  link: {
    cursor: 'pointer',
  },
}));

interface OwnProps {}

export const Register: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const auth = useAuth();

  const close = React.useCallback(() => {
    auth.setState(AuthState.SignIn);
  }, [auth]);

  return (
    <Dialog
      id="dialog-register"
      PaperProps={{
        style: {
          width: 380,
          borderRadius: 0,
        },
      }}
      open={auth.state === AuthState.Register}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <PopupHeader title={'Registreren'} handleClose={close} />
        <RegistrationForm />
      </DialogContent>
      <div className={classes.footer}>
        <Link onClick={close} className={classes.link}>
          <Typography>Terug naar inloggen</Typography>
        </Link>
      </div>
    </Dialog>
  );
};

export default Register;

import React from "react";
import { makeStyles, Button, Link as NavLink } from "@material-ui/core";

import { LoginButton } from "../../../components/auth/login-button";
import { RegisterButton } from "../../../components/auth/register-button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

interface OwnProps {}

export const TopNav: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NavLink
        className={classes.link}
        href={"https://enschedepromotie.nl/enschede-promotie/kernactiviteiten/"}
        target={"_blank"}
      >
        <Button className={classes.button} variant={"text"} color={"secondary"}>
          Over ons
        </Button>
      </NavLink>

      <LoginButton />
      <RegisterButton />
    </div>
  );
};

import { Button } from '@material-ui/core';
import * as React from 'react';
import { useAuth } from '../../../hooks/use-auth';
// import {UserInfo} from "../../layout/header/user-info";
import { AuthState } from '../../services/auth';

export const RegisterButton: React.FC = (props) => {
  const auth = useAuth();

  return (

      <Button
          onClick={(e) => {
            auth.setState(AuthState.Register);
          }}
          variant="text"
          color="secondary"
          style={{marginLeft: "10px"}}
        >
          Registreren
        </Button>
  );
};

import React from "react";
import { makeStyles, Typography, Link, Grid } from "@material-ui/core";
import logo from "../../../../images/ENSCHEDE logo RGB.svg";

const useStyles = makeStyles((theme) => ({
  legal: {
    marginTop: theme.spacing(6),
  },
  link: {
    color: theme.palette.secondary.contrastText,
    fontSize: "1em",
    fontWeight: 300,
  },
  logo:{
    textAlign: "right"
  },
  logoRow: {
    display: "flex", 
    alignItems: "flex-end",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      alignItems: "flex-start",
      marginTop: theme.spacing(2)
    }
  }
}));

interface OwnProps {}

export const Legal: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const date = new Date().getFullYear()
  return (
    <div className={classes.legal}>
      <Grid container>
        <Grid item xs={12} md={6}>
      <Typography variant={"body1"}>
        <span>&copy;&nbsp; {date}</span>&nbsp;|&nbsp;
        <Link className={classes.link} href={"https://enschedepromotie.nl/privacybeleid/"} target={"_blank"}>
          Privacybeleid
        </Link>
        &nbsp;|&nbsp;
        <Link className={classes.link} href={"https://enschedepromotie.nl/disclaimer/"} target={"_blank"}>
          Disclaimer
        </Link>
      </Typography>
      </Grid>
      <Grid item xs={12} md={6} className={classes.logoRow}>    
        <img width={"175px"}src={logo} alt={"Enschede logo"}/>
      </Grid>
      </Grid>
    </div>
  );
}
